import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { RequireAuth } from "./AuthProvider";
import Login from "./Login";
import Dashboard from "./Dashboard";

export default function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}
