import * as React from "react";
import { Navigate, useLocation } from "react-router";

const authContext = React.createContext();

export function useAuth() {
  const [authed, setAuthed] = React.useState(
    localStorage.getItem("Swach_Logged_In") === "true"
  );

  return {
    authed,
    login() {
      return new Promise((res) => {
        setAuthed(true);
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        localStorage.clear();
        setAuthed(false);
        res();
      });
    },
  };
}

export function RequireAuth({ children }) {
  const { authed } = useAuth();
  const location = useLocation();

  return authed === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
