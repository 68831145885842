import { useEffect, useState } from "react";
import { ReactComponent as Icon } from "./image/refresh-icon.svg";
import { ReactComponent as PlusIcon } from "./image/pluse-icon.svg";
import { ReactComponent as SearchIcon } from "./image/search-icon.svg";
import { ReactComponent as LeftIcon } from "./image/left-circle-svgrepo-com.svg";
import { ReactComponent as RightIcon } from "./image/right-circle-svgrepo-com.svg";
import React from "react";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router";

const useFetch = (url) => {
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setResponseData(data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return responseData;
};

const Layout = ({ children, onBack, onNext, disabledBack, disabledNext }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <div className="container">
      {onBack && (
        <LeftIcon
          onClick={() => !disabledBack && onBack()}
          style={{
            position: "sticky",
            top: "80%",
            fontSize: "3rem",
            left: 0,
            zIndex: 999,
            ...(disabledBack ? { opacity: 0.2 } : {}),
          }}
        />
      )}
      {onNext && (
        <RightIcon
          onClick={() => !disabledNext && onNext()}
          style={{
            position: "sticky",
            top: "80%",
            fontSize: "3rem",
            left: "80%",
            zIndex: 999,
            ...(disabledNext ? { opacity: 0.2 } : {}),
          }}
        />
      )}
      <div id="dynamicForm">
        <div className="header">
          <div className="logo">
            <img src={require("./image/logo.png")} alt="logo" />
          </div>
          <div className="profile">
            <div
              onClick={() => {
                logout();
                navigate("/login");
              }}
            >
              <img
                src={
                  localStorage.getItem("photo_link") ??
                  require("./image/profile.jpeg")
                }
                alt="profile"
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

const handleBack = (current, rememberMe, data) => {
  let back = current - 1;

  if (back === 6) {
    if (data["wasteCollectionServiceGroup"] === "Unallocated") {
      back = handleBack(back, rememberMe, data);
    }
  }
  return back;
};

const handleNext = (current, rememberMe, data) => {
  let next = current + 1;

  if (next === 6) {
    if (data["wasteCollectionServiceGroup"] === "Unallocated") {
      next = handleNext(next, rememberMe, data);
    }
  }
  return next;
};

const calculateNext = (current, rememberMe, data) => {
  let next = current + 1;
  if (next === 0 && rememberMe["mapSlumGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }
  if (next === 1 && rememberMe["slumNameGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }
  if (next === 2 && rememberMe["recordGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }
  if (next === 3 && rememberMe["shiftGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }

  if (next === 6) {
    if (data["wasteCollectionServiceGroup"] === "Unallocated") {
      next = calculateNext(next, rememberMe, data);
    }
  }
  return next;
};

const Dashboard = () => {
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe")
      ? JSON.parse(localStorage.getItem("rememberMe"))
      : {}
  );
  const [data, setData] = useState(
    localStorage.getItem("userInputData")
      ? JSON.parse(localStorage.getItem("userInputData"))
      : {}
  );
  const [index, setIndex] = useState(calculateNext(-1, rememberMe, data));
  const [searchTxt, setSearchTxt] = useState("");
  const onNext = () => setIndex(handleNext(index, rememberMe, data));
  const onBack = () => setIndex(handleBack(index, rememberMe, data));
  const onRest = () => {
    setData({});
    setRememberMe({});
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("userInputData");
    setIndex(handleNext(-1, {}, {}));
  };
  const userList = useFetch(
    "https://poornachakra.com/TeamAPCC/index.php/api/Users/fetchAllUsers"
  );

  const handleChange = (fieldName) => (value) => {
    if (rememberMe[fieldName]) {
      const oldData = localStorage.getItem("userInputData")
        ? JSON.parse(localStorage.getItem("userInputData"))
        : {};
      localStorage.setItem(
        "userInputData",
        JSON.stringify({
          ...oldData,
          [fieldName]: value,
        })
      );
    }

    setData({
      ...data,
      [fieldName]: value,
    });
  };

  const RememberMe = ({ fieldName }) => (
    <div className="form-group remember-me">
      <input
        type="checkbox"
        id={fieldName}
        value={fieldName}
        checked={rememberMe[fieldName]}
        onChange={(e) => {
          localStorage.setItem(
            "rememberMe",
            JSON.stringify({
              ...rememberMe,
              [fieldName]: e.target.checked,
            })
          );
          setRememberMe({
            ...rememberMe,
            [fieldName]: e.target.checked,
          });
        }}
      />
      <label htmlFor={fieldName}>Remember Choice</label>
    </div>
  );

  if (index === 0) {
    return (
      <div className="container">
        <RightIcon
          onClick={() => data["mapSlumGroup"] && onNext()}
          style={{
            position: "sticky",
            top: "80%",
            fontSize: "3rem",
            left: "80%",
            ...(data["mapSlumGroup"] ? {} : { opacity: 0.2 }),
          }}
        />
        <div id="sign-up-form">
          <Icon onClick={onRest} className="icon-refresh icon-refresh-header" />
          <img src={require("./image/logo.png")} alt="Poornachakra Cart" />
          <div id="mapSlumWarpper">
            <RememberMe fieldName="mapSlumGroup" />
            <div className="form-group form-button" id="mapSlumGroup">
              <div>
                {["MAP SLUM", "MAP NON-SLUM"].map((field) => (
                  <React.Fragment key={field}>
                    <input
                      id={field}
                      type="radio"
                      name="mapSlumGroup"
                      className="radioOption"
                      value={field}
                      disabled={field === "MAP NON-SLUM"}
                      checked={data["mapSlumGroup"] === field}
                      onChange={(e) => {
                        handleChange("mapSlumGroup")(e.target.value);
                      }}
                    />
                    <label htmlFor={field} className="button">
                      {field}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (index === 1) {
    return (
      <Layout
        onNext={onNext}
        onBack={onBack}
        disabledNext={!data["slumNameGroup"]}
      >
        <div id="slumNameWrapper">
          <Icon
            onClick={onRest}
            className="icon-refresh icon-refresh-below-header"
          />
          <h3>Select slum name</h3>
          <RememberMe fieldName="slumNameGroup" />
          <div className="form-group form-button" id="slumNameGroup">
            <div>
              {[
                "बुरुंज अली",
                "मिरेकर वस्ती",
                "समतानगर",
                "इंदिरा नगर",
                "समर्थ नगर",
                "सिद्धार्थ नगर",
                "साळवे नगर",
                "शनि आळी",
                "अशोक नगर",
                "कामराज नगर",
              ].map((field) => (
                <React.Fragment key={field}>
                  <input
                    id={field}
                    type="radio"
                    name="slumNameGroup"
                    className="radioOption"
                    value={field}
                    checked={data["slumNameGroup"] === field}
                    onChange={(e) => {
                      handleChange("slumNameGroup")(e.target.value);
                    }}
                  />
                  <label htmlFor={field} className="button button-solid">
                    {field}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (index === 2) {
    return (
      <Layout
        onNext={onNext}
        onBack={onBack}
        disabledNext={!data["recordGroup"]?.length}
      >
        <div id="recordGroupWrapper">
          <div className="top-bar">
            <input
              type="text"
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              placeholder="Search..."
            />
            <SearchIcon />
          </div>
          <Icon
            onClick={onRest}
            className="icon-refresh icon-refresh-record-group"
          />
          <RememberMe fieldName="recordGroup" />
          <div className="form-group" id="recordGroup">
            {userList
              .filter((u) =>
                u.MemberName?.toLowerCase().includes(searchTxt?.toLowerCase())
              )
              .map((user) => (
                <div className="record-item">
                  <input
                    id={`record_${user.id}`}
                    type="checkbox"
                    className="checkboxOption"
                    checked={data["recordGroup"]?.find((u) => u.id === user.id)}
                    onChange={() => {
                      handleChange("recordGroup")(
                        data["recordGroup"]
                          ? [...data["recordGroup"], user]
                          : [user]
                      );
                    }}
                  />
                  <label
                    htmlFor={`record_${user.id}`}
                    className="record-container"
                  >
                    <div className="record-header-wrapper">
                      <img src={user.Member_Photo} alt="logo" />
                      <div className="record-header-content">
                        <div className="info">
                          <strong>SWaCH ID:</strong> {user.SWaCHId}
                        </div>
                        <div className="info">
                          <strong>Name:</strong> {user.MemberName}
                        </div>
                        <div className="info">
                          <strong>Mobile No:</strong> {user.MemberMobile}
                        </div>
                        <div className="info">
                          <strong>Date:</strong> {user.created_at}
                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <strong>Kothi:</strong> {user.Kothi}
                    </div>
                    <div className="info">
                      <strong>Address:</strong> {user.MemberAddress}
                    </div>
                  </label>
                </div>
              ))}
          </div>
        </div>
      </Layout>
    );
  }

  if (index === 3) {
    return (
      <Layout
        onNext={onNext}
        onBack={onBack}
        disabledNext={!data["shiftGroup"]}
      >
        <div id="shiftWrapper">
          <Icon
            onClick={onRest}
            className="icon-refresh icon-refresh-below-header"
          />
          <h3>Select shift</h3>
          <RememberMe fieldName="shiftGroup" />
          <div className="form-group form-button" id="shiftGroup">
            <div>
              {["Morning", "Evening"].map((field) => (
                <React.Fragment key={field}>
                  <input
                    id={field}
                    type="radio"
                    name="shiftGroup"
                    className="radioOption"
                    value={field}
                    checked={data["shiftGroup"] === field}
                    onChange={(e) => {
                      handleChange("shiftGroup")(e.target.value);
                    }}
                  />
                  <label htmlFor={field} className="button button-solid">
                    {field}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (index === 4) {
    return (
      <Layout
        onNext={onNext}
        onBack={onBack}
        disabledNext={!data["wasteCollectionServiceGroup"]}
      >
        <div id="uploadFileWrapper">
          <Icon
            onClick={onRest}
            className="icon-refresh icon-refresh-below-header"
          />
          <div className="form-group upload-file">
            <div className="upload-file-edit">
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                capture
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    var reader = new FileReader();

                    reader.onload = function (e) {
                      handleChange("imagePreview")(e.target.result);
                    };

                    reader.readAsDataURL(e.target.files[0]);
                  }
                }}
              />
              <PlusIcon className="upload-file-preview-pluse" alt="" />
              <label htmlFor="fileInput">Upload photo</label>
              <img
                className="upload-file-preview-image"
                id="imagePreview"
                src={data["imagePreview"]}
                alt=""
                style={data["imagePreview"] ? {} : { display: "none" }}
              />
            </div>
          </div>
          <div
            className="form-group form-button"
            id="wasteCollectionServiceGroup"
          >
            <div>
              <h3>Waste collection service</h3>
              {[
                { label: "Doorstep Service", note: "सर्विस चालू आहे" },
                { label: "Gully/Corner Collection", note: "सर्विस चालू आहे" },
                {
                  label: "Allocated but Unallocated",
                  note: "मेम्बर ला दिले पन जात नाही",
                },
                { label: "Unallocated", note: "कोणालाच दिलेले नाही" },
              ].map((field) => (
                <React.Fragment key={field.label}>
                  <input
                    id={field.label}
                    type="radio"
                    name="wasteCollectionServiceGroup"
                    className="radioOption"
                    value={field.label}
                    checked={
                      data["wasteCollectionServiceGroup"] === field.label
                    }
                    onChange={(e) => {
                      handleChange("wasteCollectionServiceGroup")(
                        e.target.value
                      );
                    }}
                    disabled={!data["imagePreview"]}
                  />
                  <label
                    htmlFor={field.label}
                    className="button button-solid"
                    style={{ flexDirection: "column" }}
                  >
                    {field.label}
                    <span style={{ fontSize: "0.8rem" }}>{field.note}</span>
                  </label>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (index === 5) {
    return (
      <Layout onNext={onNext} onBack={onBack}>
        <>
          <div id="doorStepCollectionwrapper">
            <Icon
              onClick={onRest}
              className="icon-refresh icon-refresh-below-header"
            />
            <div className="form-group" id="doorStepPropertyGroup">
              <h3>Property Count:</h3>
              <div className="form-number" id="doorStepResidentialGroup">
                <label htmlFor="residential">Residential</label>
                <input
                  type="number"
                  id="doorStepResidential"
                  value={data["doorStepResidential"]}
                  defaultValue={0}
                  onChange={(e) =>
                    handleChange("doorStepResidential")(e.target.value)
                  }
                />
              </div>
              <div className="form-number" id="doorStepCommercialGroup">
                <label htmlFor="commercial">commercial</label>
                <input
                  type="number"
                  id="doorStepCommercial"
                  value={data["doorStepCommercial"]}
                  defaultValue={0}
                  onChange={(e) =>
                    handleChange("doorStepCommercial")(e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-group form-button" id="doorStepManagedGroup">
            <div className="form-group" id="doorStepDropOutPropertyGroup">
              <h3>Property Status:</h3>
              {data["wasteCollectionServiceGroup"] !==
                "Allocated but Unallocated" &&
                data["wasteCollectionServiceGroup"] !== "Unallocated" && (
                  <div className="form-number" id="doorStepDropOutGroup">
                    <label htmlFor="doorStepDropOut">
                      Drop out
                      <br />
                      मेम्बर जातात पन नागरिक देत नाही
                    </label>
                    <input
                      type="number"
                      id="doorStepDropOut"
                      value={data["doorStepDropOut"]}
                      max={
                        +data["doorStepResidential"] +
                        +data["doorStepCommercial"]
                      }
                      defaultValue={0}
                      onChange={(e) => {
                        handleChange("doorStepDropOut")(e.target.value);
                      }}
                      onBlur={(e) => {
                        const maxCount =
                          +data["doorStepResidential"] +
                          +data["doorStepCommercial"];

                        if (maxCount < +data["doorStepDropOut"]) {
                          handleChange("doorStepDropOut")("");
                        }
                      }}
                    />
                  </div>
                )}
              <div className="form-number" id="doorStepClosedGroup">
                <label htmlFor="closed">
                  closed
                  <br />
                  प्रॉपर्टी बंद आहे
                </label>
                <input
                  type="number"
                  id="closed"
                  defaultValue={0}
                  max={
                    +data["doorStepResidential"] +
                    +data["doorStepCommercial"] -
                    +data["doorStepDropOut"]
                  }
                  value={data["doorStepClosedGroup"]}
                  onChange={(e) =>
                    handleChange("doorStepClosedGroup")(e.target.value)
                  }
                  onBlur={(e) => {
                    const maxCount =
                      +data["doorStepResidential"] +
                      +data["doorStepCommercial"] -
                      +data["doorStepDropOut"];

                    if (maxCount < +data["doorStepClosedGroup"]) {
                      handleChange("doorStepClosedGroup")("");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      </Layout>
    );
  }

  if (index === 6) {
    return (
      <Layout
        onNext={onNext}
        onBack={onBack}
        disabledNext={!data["gateCollectionFeedbackGroup"]}
      >
        <div id="gateCollectionPaymentwrapper">
          <Icon
            onClick={onRest}
            className="icon-refresh icon-refresh-below-header"
          />
          <div
            className="form-group form-button"
            id="gateCollectionPaymentGroup"
          >
            <div>
              <h3>Mode of user fee payment</h3>
              {["Cheque", "Cash"].map((field) => (
                <React.Fragment key={field}>
                  <input
                    id={field}
                    type="radio"
                    name="gateCollectionPaymentGroup"
                    className="radioOption"
                    value={field}
                    checked={data["gateCollectionPaymentGroup"] === field}
                    onChange={(e) => {
                      handleChange("gateCollectionPaymentGroup")(
                        e.target.value
                      );
                    }}
                  />
                  <label htmlFor={field} className="button button-solid">
                    {field}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </div>

          {data["wasteCollectionServiceGroup"] !== "Unallocated" && (
            <div className="form-group" id="gateCollectionFeeGroup">
              <h3>Monthly User Fee of waste picker</h3>
              <div className="form-number">
                <input
                  type="number"
                  id="gateCollectionFee"
                  disabled={!data["gateCollectionPaymentGroup"]}
                  value={data["gateCollectionFee"]}
                  onChange={(e) =>
                    handleChange("gateCollectionFee")(e.target.value)
                  }
                />
              </div>
            </div>
          )}

          <div
            className="form-group form-button"
            id="gateCollectionFeedbackGroup"
          >
            <div>
              <h3>Service related feedback</h3>
              {["daily", "once in two days", "less than once in two days"].map(
                (field) => (
                  <React.Fragment key={field}>
                    <input
                      id={field}
                      type="radio"
                      name="gateCollectionFeedbackGroup"
                      className="radioOption"
                      value={field}
                      disabled={!data["gateCollectionFee"]}
                      checked={data["gateCollectionFeedbackGroup"] === field}
                      onChange={(e) => {
                        handleChange("gateCollectionFeedbackGroup")(
                          e.target.value
                        );
                      }}
                    />
                    <label htmlFor={field} className="button button-solid">
                      {field}
                    </label>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (index === 7) {
    return (
      <Layout onBack={onBack}>
        <>
          <div id="gateCollectionContactDetails">
            <Icon
              onClick={onRest}
              className="icon-refresh icon-refresh-below-header"
            />
            <h3>Contact details</h3>
            <div className="form-group" id="gateCollectionNameGroup">
              <input
                type="text"
                placeholder="Type name"
                id="gateCollectionName"
                value={data["gateCollectionName"]}
                onChange={(e) => {
                  handleChange("gateCollectionName")(e.target.value);
                }}
              />
            </div>

            <div className="form-group" id="gateCollectionNumberGroup">
              <input
                type="text"
                placeholder="Type Contact number"
                id="gateCollectionContactNumber"
                value={data["gateCollectionContactNumber"]}
                onChange={(e) => {
                  handleChange("gateCollectionContactNumber")(e.target.value);
                }}
              />
            </div>

            <div
              className="form-group form-button"
              id="gateCollectionTitleGroup"
            >
              <div>
                <h3>Contact Type</h3>
                {["Property Owner", "Tenant"].map((field) => (
                  <React.Fragment key={field}>
                    <input
                      id={field}
                      type="radio"
                      name="gateCollectionTitleGroup"
                      className="radioOption"
                      value={field}
                      checked={data["gateCollectionTitleGroup"] === field}
                      onChange={(e) => {
                        handleChange("gateCollectionTitleGroup")(
                          e.target.value
                        );
                      }}
                    />
                    <label htmlFor={field} className="button button-solid">
                      {field}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <button
            id="submit"
            className="submit-btn"
            onClick={() => {
              alert("Property Mapped Successfully.");
              console.log(data);
              const savedData = localStorage.getItem("userInputData")
                ? JSON.parse(localStorage.getItem("userInputData"))
                : {};

              const savedRememberMe = localStorage.getItem("rememberMe")
                ? JSON.parse(localStorage.getItem("rememberMe"))
                : {};
              setData(savedData);
              setRememberMe(savedRememberMe);
              setIndex(calculateNext(-1, savedRememberMe, savedData));
            }}
          >
            Submit
          </button>
        </>
      </Layout>
    );
  }
};

export default Dashboard;
